// FontAwesome icons

// Use plugins to only use the features we need
// Old import: import { config, dom, library } from 'Vendor/@fortawesome/fontawesome-svg-core'
// Docs: https://fontawesome.com/docs/apis/javascript/plugins
import {
	InjectCSS,
	Layers,
	// LayersCounter,
	// LayersText,
	// Masks,
	MutationObserver,
	PowerTransforms,
	register,
	ReplaceElements,
} from 'Vendor/@fortawesome/fontawesome-svg-core/plugins.mjs';

const api = register([
	InjectCSS,
	Layers,
	// LayersCounter,
	// LayersText,
	// Masks,
	MutationObserver,
	PowerTransforms,
	ReplaceElements,
])

api.config.autoReplaceSvg = 'nest';
api.config.observeMutations = true;

// Solid icons
import { faAngleLeft   } from 'Vendor/@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleRight  } from 'Vendor/@fortawesome/free-solid-svg-icons/faAngleRight';
import { faAnglesUp    } from 'Vendor/@fortawesome/free-solid-svg-icons/faAnglesUp';
import { faArrowRight  } from 'Vendor/@fortawesome/free-solid-svg-icons/faArrowRight';
import { faBullhorn    } from 'Vendor/@fortawesome/free-solid-svg-icons/faBullhorn';
import { faCheck       } from 'Vendor/@fortawesome/free-solid-svg-icons/faCheck';
import { faCircle      } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircle';
import { faCopy        } from 'Vendor/@fortawesome/free-solid-svg-icons/faCopy';
import { faCreditCard  } from 'Vendor/@fortawesome/free-solid-svg-icons/faCreditCard';
import { faExclamation } from 'Vendor/@fortawesome/free-solid-svg-icons/faExclamation';
import { faGear        } from 'Vendor/@fortawesome/free-solid-svg-icons/faGear';
import { faPencil      } from 'Vendor/@fortawesome/free-solid-svg-icons/faPencil';
import { faQuoteLeft   } from 'Vendor/@fortawesome/free-solid-svg-icons/faQuoteLeft';
import { faQuoteRight  } from 'Vendor/@fortawesome/free-solid-svg-icons/faQuoteRight';
import { faSpinner     } from 'Vendor/@fortawesome/free-solid-svg-icons/faSpinner';
import { faXmark       } from 'Vendor/@fortawesome/free-solid-svg-icons/faXmark';

// Ticketing Form specific
import { faCircleExclamation } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircleExclamation';
import { faHourglassHalf     } from 'Vendor/@fortawesome/free-solid-svg-icons/faHourglassHalf';
import { faShoppingBasket    } from 'Vendor/@fortawesome/free-solid-svg-icons/faShoppingBasket';

api.library.add({
	faAngleLeft,
	faAngleRight,
	faAnglesUp,
	faArrowRight,
	faBullhorn,
	faCheck,
	faCircle,
	faCircleExclamation,
	faCopy,
	faCreditCard,
	faExclamation,
	faGear,
	faHourglassHalf,
	faPencil,
	faQuoteLeft,
	faQuoteRight,
	faShoppingBasket,
	faSpinner,
	faXmark,
})

// Brand icons
import { faFacebookF } from 'Vendor/@fortawesome/free-brands-svg-icons/faFacebookF';
import { faTwitter   } from 'Vendor/@fortawesome/free-brands-svg-icons/faTwitter';
import { faWhatsapp  } from 'Vendor/@fortawesome/free-brands-svg-icons/faWhatsapp';

api.library.add({
	faFacebookF,
	faTwitter,
	faWhatsapp,
});

// Add our own icons
import { faDirectDebit } from 'Scripts/common/icons/faDirectDebit';

api.library.add({faDirectDebit});

// Replace and watch
api.dom.i2svg();
api.dom.watch();