'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fab';
var iconName = 'direct-debit';
var width = 720;
var height = 512;
var ligatures = [];
var unicode = '';
var svgPathData = 'M377.6,189.1v274.5c0,0-159.2-28.7-159.2-137.8C218.5,238.7,336.3,200.4,377.6,189.1z M377.6,183.9 C282,177,65,221.9,65,320c0,64,114.9,157.5,302,160c7.4,0,219.9-8.2,216.6-236.3c-2.8-191.6-146.1-225.1-192-231V1 c182.8,11.6,326.6,121.5,326.6,255c0,141.1-160.8,256-358.6,256S1.8,397.1,1.8,256S161.8,0,359.6,0c6.1,0,12.1,0.1,18.1,0.3 L377.6,183.9L377.6,183.9L377.6,183.9z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]};

exports.faDirectDebit = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;